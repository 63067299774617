function printTaxDeatils(){
	var divToPrint = document.getElementById('printGeotax');
	var printHtml = '<html><head><title>GeoTAX</title><style>';
	printHtml += 'body{margin:20px 10px;}';
	printHtml += '.printbtn{display:none;}';
	printHtml += '.floatLeft{float:left;}';
	printHtml += '.leftPad{float:left; padding:0px 0px 0px 20px;}';
	printHtml += '.printClear{clear:both;}';
	printHtml += '.printMatchedAddress{float:left;}';
	printHtml += '.printTaxrateAmt{float:right;}';
	printHtml += '.table{width:100%; margin-top:20px; border:1px solid #c8cee4; }';
	printHtml += '.table-open{border-style:solid none none; background-color:transparent;}';
	printHtml += '.table>thead:first-child>tr:first-child>th{border-top:0;}';
	printHtml += '.text-center{text-align:center;}';
	printHtml += '.text-right{text-align:right;}';
	printHtml += '.table>thead>tr>th, .table>tbody>tr>td{padding:5px 8px; vertical-align:top}';
	printHtml += '.table>tbody>tr>td{border-top:1px solid #c8cee4;}';
	printHtml += '.table-open>tbody>tr:last-child>td{border-bottom:1px solid #c8cee4;}';
	printHtml += '.printFooter{position:fixed; bottom:0; left:0;}';
	printHtml += '.totalWithTax{text-align: right; padding: 0px 10px 0px 0px;}';
	printHtml += '</style></head><body onload="window.print()">';
	printHtml += divToPrint.innerHTML+'<div class="printFooter">https://www.geotax.com</div></body></html>';
	var newWin = window.open('','Print-Window');
	newWin.document.open();
	newWin.document.write(printHtml);
	newWin.document.close();
	setTimeout(function(){newWin.close();},10);
}